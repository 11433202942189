import React from 'react';
import FlexChat from './flexChat';
import ReactGA from 'react-ga';
import getConstants from './Constants';
import getText from './Texts';
import emailClock__Img from '../assets/img/clock.png';
import axios from 'axios'

class App extends React.Component {
  state = {
    limitTimeStart: getConstants().TIMELIMITBEGIN,
    limitTimeEnd: getConstants().TIMELIMITEND,
  };

  constructor(props) {
    super(props);
    this.state = {
      workersStatus: true,
      timeWorkStatus: this.verifyTime(),
    }
  }

  verifyStatus () {

    axios.get(getConstants().APIURL + 'wsGetWorkers.php')
    .then(response => {

      console.log('response data: ', response.data);

      if (typeof(Storage) !== "undefined") {
        localStorage.setItem('status_general_workers', response.data.status);
        localStorage.getItem("status_general_workers");
        
      }
      this.setState({workersStatus: response.data.status});

    });

  };

  verifyTime = () => {

    this.verifyStatus();

    const d = new Date();
    const n = d.getHours();

    console.log('chat limitTimeStart', this.state.limitTimeStart);
    console.log('chat limitTimeEnd', this.state.limitTimeEnd);

    if (n > this.state.limitTimeStart && n < this.state.limitTimeEnd) {
      return true;
    }
    return false;

  };

  initializeReactGA (category, action, label) {
    ReactGA.initialize(getConstants().AU);
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  render() {
    
    console.log('chat timeWorkStatus: ', this.state.timeWorkStatus);
    if (this.state.timeWorkStatus) {
      console.log('chat workersStatus: ', this.state.workersStatus);
      if (this.state.workersStatus) {
        
        return (
          <div className="Twilio-ClickToChat content-page">
            {<FlexChat />}
            <div className="division-page-chat-active" />
          </div>
        );
      } else {
        this.initializeReactGA("Chat Live","Chat-L no agent widget","medium:widget, what:CHAT_widget_screen3_no_agent");
        return (
          <div className="Twilio-ClickToChat content-page">
            <div align="center">
              <span className="span-img-icon-general img-sucess">
                <img
                  src={emailClock__Img}
                  alt=""
                  className="img-icon-clock"
                />
              </span>
              <span className="title">
                {getText().clickToCallMessageFour}
              </span>
              <br />
              <span className="title"> {getText().clickToCallMessageFive}</span>
              <div className="division-page" />
            </div>
          </div>
        );
      }
    } else {
      
      return (
        <div className="Twilio-ClickToChat content-page">
          <div align="center">
            <span className="span-img-icon-general img-sucess">
              <img
                src={emailClock__Img}
                alt=""
                className="img-icon-clock"
              />
            </span>
            <span className="title">
              {getText().clickToCallMessageSix} 
            </span>
            <br />
            <span className="title">
              {getText().clickToCallMessageFourOne}
            </span>
            <br />
            <br />
            <span className="title"> {getText().clickToCallMessageFive}</span>
            <br />
            <span className="title"> {getText().clickToCallMessageFiveOne}</span>
            <div className="division-page" />
          </div>
        </div>
      );
    }
  }
}

export default App;
