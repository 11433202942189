//this file is temporary, is bad solution

import ReactGA from 'react-ga';
import getConstants from './Constants';
import getText from './Texts';


const limitTimeStart = getConstants().TIMELIMITBEGIN;
const limitTimeEnd = getConstants().TIMELIMITEND;
localStorage.setItem('status_general_workers', true);

function initializeReactGA(category, action, label) {
    ReactGA.initialize(getConstants().AU);
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
}

function msgalert(msg) {
  let div = document.getElementById('div-msg-alert');
  div.style.display = 'block';
  document.getElementById('p-msg-alert').innerHTML = msg;
  setTimeout(() => {
    div.style.display = 'none';
  }, 5000);
}

function requestCall(pg, datas) {
  if (verifyTimeWork()) {
    if (datas && datas !== 'no-data') {
      let phone = datas
        .replace('+', '')
        .replace(' ', '')
        .replace('.', '')
        .replace('-', '');
      // eslint-disable-next-line no-console
      phone = `${phone}`;
      let context_url = new URL(window.location.href);
      let context = context_url.searchParams.get("context");
      console.log('context(2): ', context);
      const url = `${getConstants().APIURL}wsSendClickToCall.php?phone=${phone}&context_path=${context}&context_browser_language=en&context_location=Lisboa`;
      const Http = new XMLHttpRequest();
      Http.open('GET', url);
      Http.setRequestHeader('Content-Type', 'application/json');
      const task = {
        channel: 'phone',
        type: 'callback_request',
        phone,
        name: getText().nameTaskCall,
        text: getText().textTaskCall,
        team: 'sales',
        title: getText().titleTaskCall,
      };
      Http.send(task);
      Http.onreadystatechange = () => {
        // eslint-disable-next-line no-console
        console.log(Http.responseText);
      };
    } else if (!datas && datas !== 'no-data') {
      actionElements(pg);
      initializeReactGA("C2C Calls","C2C error invalid number widget","medium:widget, what:C2C_widget_screen1_invalid_number");
      msgalert(getText().validInputPhoneNumber);
    } else {
      actionElements(pg);
    }
  }
}

function requestMail() {
  const obj = verifyFieldsform();
  if (obj) {
    const url = `${getConstants().APIURL}wsSendMail.php?name=${
      obj.name
    }&phone=${obj.phone}&msg=${obj.msg}&mail=${obj.mail}`;
    const Http = new XMLHttpRequest();
    Http.open('GET', url);
    Http.setRequestHeader('Content-Type', 'application/json');
    Http.send(obj);
    Http.onreadystatechange = () => {
      // eslint-disable-next-line no-console
      console.log(Http.responseText);
    };
    return 'send';
  }
  return false;
}

function verifyFieldsform() {
  const obj = {
    name: document.getElementById('form_input_name').value,
    mail: document.getElementById('form_input_email').value,
    phone: document.getElementById('form_input_phone').value,
    msg: document.getElementById('form_input_msg').value,
  };
  if (obj.name && obj.mail && obj.phone && obj.msg) {
    return obj;
  }
  msgalert(getText().validInputsGeneral);
  return false;
}

function verifyMail(page_id, mail) {
  if (!mail) {
    actionElements(page_id);
    msgalert(getText().validInputMail);
  } else {
    document.getElementById('form_input_email').value = mail;
    actionElements(page_id, 'go');
  }
}

function titleHeaderText(id) {
  const texts = {
    title: getText().title,
    subtitle: getText().subtitle,
  };
  switch (id) {
    case 'page-click-to-chat':
      if (verifyTimeWork()) {
        texts.title = getText().headerTextTitleChat;
        texts.subtitle = getText().headerTextSubtitleChat;
      } else {
        texts.title = getText().headerTextTitleNoAgent;
        texts.subtitle = getText().headerTextSubtitleNoAgent;
      }
      break;
    case 'page-click-to-call':
      if (verifyTimeWork()) {
        texts.title = getText().headerTextTitleCall;
        texts.subtitle = getText().headerTextSubtitleCall;
      } else {
        texts.title = getText().headerTextTitleNoAgent;
        texts.subtitle = getText().headerTextSubtitleNoAgent;
      }
      break;
    case 'page-click-to-mail':
      texts.title = getText().headerTextTitleMail;
      texts.subtitle = getText().headerTextSubtitleMail;
      break;
    default:
      break;
  }
  return texts;
}

function actionElements(page_id, type) {
  const page = document.getElementById(page_id);
  const header_begin = document.getElementById(
    'header-page-begin',
  );
  const header_general = document.getElementById(
    'header-page-general',
  );
  const header_general_title = document.getElementById(
    'title-header-page-general',
  );
  const header_general_subtitle = document.getElementById(
    'subtitle-header-page-general',
  );
  const begin = document.getElementById('page-begin');

  if (type === 'go') {
    header_begin.style.display = 'none';
    begin.style.display = 'none';
    header_general.style.display = 'block';
    const titles = titleHeaderText(page_id);
    header_general_title.innerText = titles.title;
    header_general_subtitle.innerText = titles.subtitle;
    page.style.display = 'block';
  } else if (type === 'back') {
    header_begin.style.display = '';
    begin.style.display = '';
    const divsToHide = document.getElementsByClassName('pages');
    const inputs = document.getElementsByClassName('chat-inputs');
    for (let i = 0; i < divsToHide.length; i++) {
      divsToHide[i].style.display = 'none';
    }
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].value = '';
    }
  } else {
    header_begin.style.display = '';
    begin.style.display = '';
    const divsToHide = document.getElementsByClassName('pages');
    for (let i = 0; i < divsToHide.length; i++) {
      divsToHide[i].style.display = 'none';
    }
  }
}

function actionOpenWindowsChatFlex() {
  if (verifyTimeWork()) {
    const win = document.getElementsByClassName(
      'Twilio-MainContainer',
    )[0];
    if (!win) {
      document
        .getElementsByClassName('Twilio-EntryPoint')[0]
        .click();
    }
  }
}

function verifyTimeWork() {

  const status_general_workers = localStorage.getItem("status_general_workers");
  // status_general_workers = (status_general_workers === 'true');
  const d = new Date();
  const n = d.getHours();

  console.log('navigation status_general_workers: ', status_general_workers);
  console.log('navigation local time: ', n);
  console.log('navigation limitTimeStart: ', limitTimeStart);
  console.log('navigation limitTimeEnd: ', limitTimeEnd);

  if (n > limitTimeStart && n < limitTimeEnd && status_general_workers == 'true') {
    return true;
  }
  return false;
}

function openPage(page_id, datas) {

  if (page_id === 'page-click-to-call') {

    actionElements(page_id, 'go');
    requestCall(page_id, datas);

  } else if (page_id === 'page-click-to-chat') {

    actionElements(page_id, 'go');
    actionOpenWindowsChatFlex();

  } else if (page_id === 'page-click-to-mail') {

    verifyMail(page_id, datas);

  } else if (page_id === 'page-click-to-mail-form') {

    return requestMail();

  } else {

    actionElements(page_id, 'back');

  }
  return true;
}

export default openPage;
