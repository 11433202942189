/* eslint-disable prettier/prettier */
import React from 'react';
import ReactSVG from 'react-svg';
import ReactGA from 'react-ga';
import ClickToCall from './clickToCall';
import ClickToChat from './clickToChat';
import ClickToMail from './clickToMail';

//this import is temporary
import getConstants from './Constants';
import openPage from './TempNavigation';
import getText from './Texts';

// import Slot_Slot from './Slots/Chat';
import Main_Icon from '../assets/img/Main-Icon.svg';
import chat__Img from '../assets/img/chat.svg';
import callClient__Img from '../assets/img/call-center.svg';
// import callUs__Img from '../assets/img/phone.svg';
import email__Img from '../assets/img/email.svg';
// import chatBot__Img from '../assets/img/chat-bot.svg';
import close__Img from '../assets/img/close-icon-white-temp.png';
import goBack__Img from '../assets/img/left-arrow.png';
// import { MainContainer } from '@twilio/flex-webchat-ui';

const chatID = generateUID();

export default function Chat() {
  return (
    <div id={chatID} className="Twillio-Chat">
      <div className="chat-wrapper">
        <span className="toggle-btn" onClick={toggleChat}>
          <ReactSVG src={Main_Icon} alt="" />
        </span>
        <div className="chat">
          <div id="div-msg-alert" align="center">
            <p id="p-msg-alert"></p>
          </div>
          <div id="header-page-general" className="chat-header pages">
            <div className="chat-close" onClick={toggleChat}>
              <img src={close__Img} alt="" />
            </div>
            <span
              className="title"
              id="title-header-page-general"
            />
            <span className="greetings" id="subtitle-header-page-general" />
            <div
              className="chat-go-back"
              onClick={() => openPage(null, {})}
            >
            <img src={goBack__Img} alt="" />
            </div>
          </div>
          <div id="page-click-to-chat" className="pages">
            {<ClickToChat />}
          </div>
          <div id="page-click-to-call" className="pages">
            {<ClickToCall />}
          </div>
          <div id="page-click-to-mail" className="pages">
            {<ClickToMail />}
          </div>
          <div id="header-page-begin" className="chat-header">
            <div className="chat-close" onClick={toggleChat}>
              <img src={close__Img} alt="" />
            </div>
            <span className="title">{getText().title}</span>
            <span className="greetings">
              {getText().subtitle}
            </span>
          </div>
          <div id="page-begin" className="chat-slots">
            <div className="slot">
              <span className="img">
                <img src={callClient__Img} alt="" />
              </span>
              <div className="content content__action">
                <span className="title">{getText().titleCallBack}</span>
                <div>
                  <input
                    type="tel"
                    name=""
                    id="click-to-call-input"
                    placeholder={getText().plaholderCallBack}
                    className="chat-inputs"
                  />
                  <button
                    onClick={() => {
                      openPage(
                        'page-click-to-call',
                        document.getElementById(
                          'click-to-call-input',
                        ).value,
                      );
                      initializeReactGA(
                        "C2C Calls",
                        "C2C 1st click widget",
                        "medium:widget, what:C2C_widget_screen1"
                      )
                    }}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
            <div className="slot">
              <span className="img">
                <img src={chat__Img} alt="" />
              </span>
              <div
                className="content content__text"
                onClick={() => {
                  openPage('page-click-to-chat', {});
                  initializeReactGA("Chat Live","Chat-L clicks widget","medium:widget, what:CHAT_widget_screen1")
                }}
              >
                <span className="title">{getText().titleChat}</span>
                <span className="body subtitle">
                  {getText().subtitleChat}
                </span>
              </div>
            </div>
            <div className="slot">
              <span className="img">
                <img src={email__Img} alt="" />
              </span>
              <div className="content content__action">
                <span className="title">
                  {getText().titleMail}
                </span>
                <div>
                  <input
                    type="email"
                    name=""
                    id="click-to-mail-input"
                    placeholder={getText().placeholderMail}
                    className="chat-inputs"
                  />
                  <button onClick={() => {
                    openPage('page-click-to-mail', document.getElementById(
                      'click-to-mail-input',
                    ).value,);
                    initializeReactGA("Email","Email 1st click widget","medium:widget, what:Email_widget_screen1")
                  }}
                  >&gt;</button>
                </div>
              </div>
            </div>
            {/* <div className="slot">
              <span className="img">
                <ReactSVG src={callUs__Img} alt="" />
              </span>
              <div className="content content__label">
                <span className="title">Prefere ligar-nos?</span>
                <span className="label">21 888 666 555</span>
              </div>
            </div> */}

            {/* <div className="slot">
              <span className="img">
                <ReactSVG src={chatBot__Img} alt="" />
              </span>
              <div className="content content__text">
                <span className="title">Chat Bot</span>
                <span className="body">
                  Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Incidunt explicab.
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function toggleChat() {
  const chat = document.getElementById(chatID);
  chat.classList.toggle('Twillio-Chat-active');
  console.log(getConstants().VERSION);
}

function initializeReactGA(category, action, label) {
    ReactGA.initialize(getConstants().AU);
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
}

/*function handleSlotClick(e) {
  console.log(e);
}*/

function generateUID() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    c => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
}

// function slots() {}
export const that = 'HelloWorld';
