
import React from 'react';
import ReactSVG from 'react-svg';
import ReactGA from 'react-ga';
import getConstants from './Constants';
import getText from './Texts';
import email__Img from '../assets/img/email.svg';
import emailSend__Img from '../assets/img/logo_checker.png';

//this import is temporary
import openPage from './TempNavigation';

class App extends React.Component {
  state = {
    isSend: false,
  };

  sendMail = () => {
    if (openPage('page-click-to-mail-form') === 'send') {
      return this.setState({ isSend: true });
    }
    return this.setState({ isSend: false });
  };

  initializeReactGA (category, action, label) {
    ReactGA.initialize(getConstants().AU);
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  renderPage(status) {
    const form = (
      <div align="center">
        <span className="span-img-icon-general">
          <ReactSVG
            src={email__Img}
            alt=""
            className="img-icon-general"
          />
        </span>
        <span className="title">
          {getText().clickToMailMessageOne}
        </span>
        <br />
        <span className="title">
          {getText().clickToMailMessageTwo}
        </span>
        <div className="div-form-mail">
          <input
            type="text"
            placeholder=""
            id="form_input_email"
          />
          <input
            type="text"
            placeholder={getText().clickToMailPlaceholderOne}
            id="form_input_name"
          />
          <input
            type="number"
            placeholder={getText().clickToMailPlaceholderTwo}
            id="form_input_phone"
          />
          <textarea
            rows="3"
            placeholder={getText().clickToMailPlaceholderThree}
            id="form_input_msg"
          />
          <button onClick={this.sendMail}>{getText().clickToMailButtonOne}</button>
        </div>
      </div>
    );

    const sendPage = (
      <div align="center">
        <span className="span-img-icon-general img-sucess">
          <img
            src={emailSend__Img}
            alt=""
            className="img-icon-general"
          />
        </span>
        <span className="title">{getText().clickToMailMessageThree}</span>
        <br />
        <span className="subtitle">
          {getText().clickToMailMessageFour}
        </span>
        <div className="division-page" />
      </div>
    );

    let returnPage = form;

    if (status) {
      returnPage = sendPage;
      this.initializeReactGA("Email","Email form valid submission widget","medium:widget, what:Email_widget_screen3_valid");
    }

    return returnPage;
  }

  render() {
    this.initializeReactGA("Email","Email form widget","medium:widget, what:Email_widget_screen2_form");
    return (
      <div className="Twilio-ClickToMail content-page">
        {this.renderPage(this.state.isSend)}
      </div>
    );
  }
}

export default App;
