import React from 'react';
import axios from 'axios'
import ReactSVG from 'react-svg';
import ReactGA from 'react-ga';
import getConstants from './Constants';
import getText from './Texts';
import emailCall__Img from '../assets/img/phone.svg';
import emailClock__Img from '../assets/img/clock.png';

class App extends React.Component {
  state = {
    limitTimeStart: getConstants().TIMELIMITBEGIN,
    limitTimeEnd: getConstants().TIMELIMITEND,
  };

  constructor(props) {
    super(props);
    this.state = {
      workersStatus: true,
      timeWorkStatus: this.verifyTime(),
    }
  }

  verifyStatus () {

    axios.get(getConstants().APIURL + 'wsGetWorkers.php')
    .then(response => {

      if (typeof(Storage) !== "undefined") {
        localStorage.setItem('status_general_workers', response.data.status);
        localStorage.getItem("status_general_workers");
        
      }
      this.setState({workersStatus: response.data.status});

    });

  };

  verifyTime = () => {

    this.verifyStatus();

    const d = new Date();
    const n = d.getHours();

    if (n > this.state.limitTimeStart && n < this.state.limitTimeEnd) {
      return true;
    }
    return false;

  };

  initializeReactGA (category, action, label) {
    ReactGA.initialize(getConstants().AU);
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  render() {

    if (this.state.timeWorkStatus) {
      if (this.state.workersStatus) {
        this.initializeReactGA("C2C Calls","C2C valid request widget","medium:widget, what:C2C_widget_screen2_valid");
        return (
          <div className="Twilio-ClickToCall content-page">
            <div align="center">
              <span className="span-img-icon-general img-sucess">
                <ReactSVG
                  src={emailCall__Img}
                  alt=""
                  className="img-icon-general"
                />
              </span>
              <span className="title">
                {getText().clickToCallMessageOne}
              </span>
              <br />
              <span className="title title-bold">{getText().clickToCallMessageTwo}</span>
              <br />
              <span className="title">
                {getText().clickToCallMessageThree}
              </span>
              <div className="division-page" />
            </div>
          </div>
        );
      } else {
        this.initializeReactGA("C2C Calls","C2C no agent widget","medium:widget, what:C2C_widget_screen3_no_agent");
        return (
          <div className="Twilio-ClickToChat content-page">
            <div align="center">
              <span className="span-img-icon-general img-sucess">
                <img
                  src={emailClock__Img}
                  alt=""
                  className="img-icon-clock"
                />
              </span>
              <span className="title">
                {getText().clickToCallMessageFour}
              </span>
              <br />
              <span className="title"> {getText().clickToCallMessageFive}</span>
              <div className="division-page" />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="Twilio-ClickToCall content-page">
          <div align="center">
            <span className="span-img-icon-general img-sucess">
              <img
                src={emailClock__Img}
                alt=""
                className="img-icon-clock"
              />
            </span>
            <span className="title">
              {getText().clickToCallMessageSix} 
            </span>
            <br />
            <span className="title">
              {getText().clickToCallMessageFourOne}
            </span>
            <br />
            <br />
            <span className="title"> {getText().clickToCallMessageFive}</span>
            <br />
            <span className="title"> {getText().clickToCallMessageFiveOne}</span>
            <div className="division-page" />
          </div>
        </div>
      );
    }
  }
}

export default App;
