import './assets/css/style.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import Chat from './components/Chat';

const elm = document.createElement('div');
elm.id = 'clickprofit-widget';
elm.style.zIndex = '50';
ReactDOM.render(<Chat />, elm);
document.body.appendChild(elm);
