import React from 'react';
import ReactGA from 'react-ga';
import * as FlexWebChat from '@twilio/flex-webchat-ui';
import getConstants from './Constants';
import getText from './Texts';
import openPage from './TempNavigation';

const brandColor1 = '#3ba236';
const brandColor2 = '#336600';
const brandTextColor = '#ffffff';

const personalizedColors = {
  darkBlueBackground: '#3C425C',
  whiteText: '#FFFFFF',
  entryPointBackground: '#3C425C',
  lighterBackground: '#ecedf1',
  primaryButtonBackground: '#3ba236',
  primaryButtonColor: '#FFFFFF',
  secondaryButtonBackground: '#6e7180',
  secondaryButtonColor: '#FFFFFF',
  newBackgroundColor: '#f3f3f3',
  whiteBackground: '#FFFFFF',
};

const brandMessageBubbleColors = bgColor => ({
  Bubble: {
    background: bgColor,
    color: brandTextColor,
  },
  Avatar: {
    background: bgColor,
    color: brandTextColor,
  },
  Header: {
    color: brandTextColor,
  },
});

const brandedColors = {
  Chat: {
    MessageListItem: {
      FromOthers: brandMessageBubbleColors(brandColor2),
      FromMe: brandMessageBubbleColors(brandColor1),
    },
    MessageInput: {
      Button: {
        background: brandColor1,
        color: brandTextColor,
      },
      Container: {
          background: personalizedColors.whiteBackground,
      }
    },
    MessageCanvasTray: {
      Container: {
        background: personalizedColors.darkBlueBackground,
        color: personalizedColors.whiteText,
      },
    },
    MessagingCanvas: {
        Container: {
            background: personalizedColors.newBackgroundColor,
        }
    },
  },

  MainHeader: {
    Container: {
      background: brandColor1,
      color: personalizedColors.whiteText,
    },
    Logo: {
      fill: brandTextColor,
    },
  },

  EntryPoint: {
    Container: {
      background: personalizedColors.newBackgroundColor,
      color: personalizedColors.newBackgroundColor,
      '&:hover': {
        backgroundColor: personalizedColors.newBackgroundColor,
        backgroundBlendMode: personalizedColors.newBackgroundColor,
      },
    },
  },

  PreEngagementCanvas: {
    Container: {
      background: personalizedColors.newBackgroundColor,
    },

    Form: {
      SubmitButton: {
        background: personalizedColors.primaryButtonBackground,
        color: personalizedColors.whiteText,
      },
    },
  },

  LoadingView: {
    Container: {
        background: personalizedColors.newBackgroundColor,
        color: brandColor1,
    }
  },
};

const pt_PT = {
  InputPlaceHolder: 'Escreva aqui a sua questão por favor',
  TypingIndicator: '{0} a escrever ... ',
  Connecting: 'Em ligação ...',
  Disconnected: 'Ligação interrompida',
  Read: 'Lida',
  MessageSendingDisabled: 'O envio de mensagens foi desativado',
  Today: 'HOJE',
  Yesterday: 'ONTEM',
  MessageCanvasTrayContent: `<h6>Finalizado</h6> <p>Este chat já terminou.</p>`,
  MessageCanvasTrayButton: 'INICIAR CHAT',
  WelcomeMessage:
    'Obrigado por entrar em contacto com a Descontos.pt',
  NotificationMessage:
    'Não foi possível executar a ação: {{message}}',
  Save: 'GRAVAR',
  Reset: 'REINICIAR',
  PendingEngagementMessage:
    'A ligar a um operador, por favor aguarde.',
  PendingEngagementCancelButton: 'Cancelar',
  ConfirmEndEngagementMessage: 'Deseja terminar este chat?',
  ConfirmEndEngagementConfirmButton: 'SIM',
  ConfirmEndEngagementDenyButton: 'Ainda não, quero continuar',
  PreEngagementMandatoryFieldMessage:
    'Preenchimento obrigatório *',
  // eslint-disable-next-line no-dupe-keys
  MessageCanvasTrayContent:
    '<h6>Obrigado pelo seu contacto!</h6> <p>Se tiver mais alguma questão, estamos disponíveis pelos canais que selecionar (voz, chat, email).</p>',
  PredefinedChatMessageAuthorName: 'Apoio ao Cliente',
  PredefinedChatMessageBody:
    'Por favor coloque a sua questão abaixo e aguarde um momento.',
};

const configuration = {
  accountSid: getConstants().ACCOUNTSID,
  flexFlowSid: getConstants().FLEXFLOWSID,
  language: 'pt-PT',
  disableLocalStorage: false,
  available: true,
  colorTheme: {
    overrides: brandedColors,
  },
  tokenServerUrl:
    'https://iam.twilio.com/v1/Accounts/' + getConstants().ACCOUNTSID + '/Tokens',
  flexWebChannelsUrl:
    'https://flex-api.twilio.com/v1/WebChannels',
  context: {
    friendlyName: 'Visitante',
  },
  startEngagementOnInit: true,
  sdkOptions: {
    chat: {},
  },
  componentProps: {
    MainContainer: {
      height: '350px',
      width: '350px',
      startEngagementOnInit: true,
    },
    MainHeader: {
      closeCallback: () => {
        openPage('page-click-to-chat', {});
      },
    },
    EntryPoint: {
      hideTaglineWhenExpanded: true,
      tagline: '',
    },
  },
};

class App extends React.Component {
  state = {};

  constructor(props) {
    super(props);

    FlexWebChat.Manager.create(configuration)
      .then(manager => {
        if(getText().language === 'PT_pt'){
          manager.strings = pt_PT;
        }
        this.setState({ manager });
      })
      .catch(error => this.setState({ error }));
  }

  initializeReactGA (category, action, label) {
    ReactGA.initialize(getConstants().AU);
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  render() {
    const { manager, error } = this.state;
    if (manager) {
      this.initializeReactGA("Chat Live","Chat-L started interaction widget","medium:widget, what:CHAT_widget_screen2_started_chat");
      return (
        <FlexWebChat.ContextProvider manager={manager}>
          <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
      );
    }

    if (error) {
      console.error('Failed to initialize Flex Web Chat', error);
    }

    return null;
  }
}

export default App;
